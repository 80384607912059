var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.malli.registry.js");
'use strict';var tZ,uZ,Ana,yZ,zZ,Cna,AZ,BZ,DZ,EZ,FZ,GZ,HZ,JZ,KZ,LZ,Dna,OZ,PZ,Bna;
tZ=function(a,b){a=new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Lg,a],null)],null);for(var c=$CLJS.Lg;;){var d=$CLJS.he(a);if($CLJS.m(d)){var e=d,f=$CLJS.J(e,0,null),g=$CLJS.J(e,1,null),l=e,n=$CLJS.ie(a);a=function(q,u,v,x,y,B,H,I){return function(Q){return $CLJS.oh.j(v,$CLJS.rg.g(function(Y,aa,ha,qa,Ea){return function(jb){var lb=$CLJS.J(jb,0,null);jb=$CLJS.J(jb,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kf.h(Ea,lb),jb],null)}}(q,u,v,x,y,B,H,I)),Q)}}(a,c,n,e,f,g,l,d);
$CLJS.m(b.g?b.g(g):b.call(null,g))?(a=n,c=$CLJS.kf.h(c,l)):a=$CLJS.oe(g)?a(g):$CLJS.ne(g)?a($CLJS.dg($CLJS.Mm,g)):n}else return c}};uZ=function(a,b,c){return $CLJS.qe(c)&&$CLJS.F.h(a,$CLJS.C(c))&&!$CLJS.Ie(b,$CLJS.M.h(c,2))};$CLJS.vZ=function(a){var b=$CLJS.oh.j($CLJS.oi,$CLJS.rg.g($CLJS.Dm.h($CLJS.NL,$CLJS.Zd)),$CLJS.QQ.g(a));return tZ($CLJS.vm.l(a,$CLJS.WO,$CLJS.G([$CLJS.oZ])),function(c){return uZ($CLJS.zG,b,c)})};
$CLJS.wZ=function(a){var b=$CLJS.oh.j($CLJS.oi,$CLJS.rg.g($CLJS.Dm.h($CLJS.HG,$CLJS.Zd)),$CLJS.OP.g(a));return tZ($CLJS.vm.l(a,$CLJS.WO,$CLJS.G([$CLJS.oZ])),function(c){return uZ($CLJS.OP,b,c)})};Ana=function(a){function b(d){return $CLJS.sg(c,$CLJS.G([$CLJS.WO.g(d)]))}function c(d){return $CLJS.nf($CLJS.xP.g(d),$CLJS.sg(b,$CLJS.G([$CLJS.hZ.g(d)])))}return $CLJS.m($CLJS.xZ.g(a))?$CLJS.ag(!0):$CLJS.si(b(a))};
yZ=function(a){for(var b=$CLJS.ag(!1),c=$CLJS.A(a),d=$CLJS.C(c),e=$CLJS.D(c),f=b,g=0,l=a;;){var n=f,q=g,u=l,v=$CLJS.A(u),x=$CLJS.C(v),y=$CLJS.D(v),B=x,H=y,I=$CLJS.Fm.h(n,Ana(B));f=$CLJS.C($CLJS.A($CLJS.ug($CLJS.Hb,function(Q,Y,aa,ha,qa,Ea){return function Mb(lb,Fb){try{if($CLJS.qe(Fb)&&3===$CLJS.E(Fb))try{var ac=$CLJS.Td(Fb,0);if($CLJS.O(ac,$CLJS.FQ))try{var Tb=$CLJS.Td(Fb,1);if($CLJS.m($CLJS.nQ.g(Tb)))try{var Ub=$CLJS.Td(Fb,1);if(null!=Ub?Ub.C&256||$CLJS.t===Ub.bg||(Ub.C?0:$CLJS.Nb($CLJS.lc,Ub)):
$CLJS.Nb($CLJS.lc,Ub))try{var ge=$CLJS.M.j(Ub,$CLJS.nQ,$CLJS.aQ);if($CLJS.m($CLJS.$f(ha)(ge))){var $z=$CLJS.M.h(Ub,$CLJS.nQ);$CLJS.Td(Fb,2);return new $CLJS.S(null,1,5,$CLJS.T,[["Invalid :field reference in stage ",$CLJS.p.g(Ea),": no join named ",$CLJS.aj.l($CLJS.G([$z]))].join("")],null)}throw $CLJS.Z;}catch(Og){if(Og instanceof Error){var qj=Og;if(qj===$CLJS.Z)throw $CLJS.Z;throw qj;}throw Og;}else throw $CLJS.Z;}catch(Og){if(Og instanceof Error){qj=Og;if(qj===$CLJS.Z)throw $CLJS.Z;throw qj;}throw Og;
}else throw $CLJS.Z;}catch(Og){if(Og instanceof Error){qj=Og;if(qj===$CLJS.Z)throw $CLJS.Z;throw qj;}throw Og;}else throw $CLJS.Z;}catch(Og){if(Og instanceof Error){qj=Og;if(qj===$CLJS.Z)throw $CLJS.Z;throw qj;}throw Og;}else throw $CLJS.Z;}catch(Og){if(Og instanceof Error){qj=Og;if(qj===$CLJS.Z)return $CLJS.sZ(Mb,lb,Fb);throw qj;}throw Og;}}}(f,g,l,I,n,q,u,v,x,y,B,H,b,0,a,a,c,d,e,d,e)($CLJS.Lg,$CLJS.vm.l(B,$CLJS.WO,$CLJS.G([Bna]))))));if($CLJS.m(f))return f;if($CLJS.A(H))g=q+1,f=I,l=H;else return null}};
zZ=new $CLJS.N("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);Cna=new $CLJS.N("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);AZ=new $CLJS.N("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);BZ=new $CLJS.N("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.CZ=new $CLJS.N("mbql.stage","mbql","mbql.stage/mbql",1578747798);
DZ=new $CLJS.N("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);EZ=new $CLJS.N("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);FZ=new $CLJS.N("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);GZ=new $CLJS.N("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);
HZ=new $CLJS.N("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);$CLJS.IZ=new $CLJS.N("mbql.stage","native","mbql.stage/native",359422194);JZ=new $CLJS.N("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);KZ=new $CLJS.N("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);LZ=new $CLJS.N("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);
$CLJS.MZ=new $CLJS.N("mbql","query","mbql/query",-1285688662);$CLJS.xZ=new $CLJS.N(null,"source-card","source-card",-1580820390);Dna=new $CLJS.N("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);$CLJS.NZ=new $CLJS.N(null,"filters","filters",974726919);OZ=new $CLJS.N("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);PZ=new $CLJS.N("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);
$CLJS.QZ=new $CLJS.N("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);Bna=new $CLJS.N("stage","metadata","stage/metadata",1707239131);$CLJS.jL(FZ,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Ql,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ll,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zl,$CLJS.IZ],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.VQ,$CLJS.Lb],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.el,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gl,$CLJS.Lb],null)],null)],null));$CLJS.jL(zZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gl,new $CLJS.k(null,1,[$CLJS.lk,1],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.DV],null)],null));
$CLJS.jL(KZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gl,new $CLJS.k(null,1,[$CLJS.lk,1],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.DV],null)],null));$CLJS.jL(JZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gl,new $CLJS.k(null,1,[$CLJS.lk,1],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.KL],null)],null));
var RZ=$CLJS.Fm.h(function(a){a=$CLJS.C($CLJS.vZ(a));return $CLJS.m(a)?["Invalid :expression reference: no expression named ",$CLJS.aj.l($CLJS.G([$CLJS.Ju(a,new $CLJS.S(null,2,5,$CLJS.T,[1,2],null))]))].join(""):null},function(a){a=$CLJS.C($CLJS.wZ(a));return $CLJS.m(a)?["Invalid :aggregation reference: no aggregation with uuid ",$CLJS.p.g($CLJS.Ju(a,new $CLJS.S(null,2,5,$CLJS.T,[1,2],null)))].join(""):null});
$CLJS.jL(LZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,2,[$CLJS.ev,"Valid references for a single query stage",$CLJS.Xu,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.kl);return RZ(a)}],null),$CLJS.$f(RZ)],null));
$CLJS.jL($CLJS.QZ,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Kj,new $CLJS.S(null,11,5,$CLJS.T,[$CLJS.Ql,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ll,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zl,$CLJS.CZ],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.WO,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.fZ],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.QQ,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.IL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,
[$CLJS.HP,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),zZ],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.OP,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.mW],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yO,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),KZ],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.NZ,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),JZ],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.$R,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.lZ],
null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.$P,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.gV],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xZ,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.hV],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.ev,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Ie(a,$CLJS.mR)}],null),new $CLJS.S(null,3,5,$CLJS.T,
[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.ev,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.$f($CLJS.Em.h($CLJS.$P,$CLJS.xZ))],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,LZ],null)],null));$CLJS.jL(AZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.QZ],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ql,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$P,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.gV],null)],null)],null)],null));
$CLJS.jL(BZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.QZ],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ql,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.hV],null)],null)],null)],null));$CLJS.jL(EZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,AZ],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,BZ],null)],null));
$CLJS.jL(GZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.QZ],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.ev,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.$f($CLJS.Fm.h($CLJS.$P,$CLJS.xZ))],null)],null));$CLJS.jL(HZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Gt,$CLJS.IZ,$CLJS.CZ],null));
$CLJS.jL(Cna,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ql,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ll,HZ],null)],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Nt,new $CLJS.k(null,1,[$CLJS.rk,$CLJS.ll],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.IZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,FZ],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.CZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.QZ],null)],null)],null)],null));
$CLJS.jL(PZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ql,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ll,HZ],null)],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Nt,new $CLJS.k(null,1,[$CLJS.rk,$CLJS.ll],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.IZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,FZ],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.CZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,EZ],null)],null)],null)],null));$CLJS.jL(DZ,GZ);
$CLJS.jL(OZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,2,[$CLJS.ev,"Valid references for all query stages",$CLJS.Xu,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.kl);return yZ.g?yZ.g(a):yZ.call(null,a)}],null),$CLJS.$f(yZ)],null));
$CLJS.jL($CLJS.dZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kj,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,PZ],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,DZ],null)],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,OZ],null)],null));
$CLJS.jL(Dna,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kj,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Ql,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ll,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zl,$CLJS.MZ],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.NQ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wk,$CLJS.iV,$CLJS.jV],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.dZ],null)],null)],null),$CLJS.zna],null));