var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./schema.utils.js");require("./schema.spec.core.js");
'use strict';var Jga,EM,KM,IM,LM,MM;
Jga=function(a,b,c){if($CLJS.qe(a)){var d=$CLJS.C(a);d=d instanceof $CLJS.N?d.S:null;switch(d){case "schema.spec.collection/optional":return a=$CLJS.D(a),$CLJS.DM.j?$CLJS.DM.j(a,b,c):$CLJS.DM.call(null,a,b,c);case "schema.spec.collection/remaining":if(!$CLJS.F.h(2,$CLJS.E(a)))throw Error($CLJS.XL("remaining can have only one schema."));var e=$CLJS.pM($CLJS.Zd(a),b);return function(g,l){$CLJS.dj.j(g,$CLJS.oh,$CLJS.rg.h(e,l));return c.h?c.h(g,null):c.call(null,g,null)};default:throw Error(["No matching clause: ",
$CLJS.p.g(d)].join(""));}}else{var f=$CLJS.kk.g(a);e=$CLJS.pM(a,b);return function(g,l){var n=function(){function q(u){return $CLJS.dj.j(g,$CLJS.kf,$CLJS.iM(u)?u:e.g?e.g(u):e.call(null,u))}return f.h?f.h(q,l):f.call(null,q,l)}();return c.h?c.h(g,n):c.call(null,g,n)}}};$CLJS.DM=function(a,b,c){if(!$CLJS.Yf(function(d){return $CLJS.qe(d)&&$CLJS.F.h($CLJS.C(d),EM)},$CLJS.ti(a)))throw Error($CLJS.XL("Remaining schemas must be in tail position."));return $CLJS.Sb(function(d,e){return Jga(e,b,d)},c,$CLJS.lf(a))};
$CLJS.FM=function(a,b,c,d,e,f,g){this.na=a;this.sc=b;this.elements=c;this.dc=d;this.F=e;this.m=f;this.G=g;this.C=2230716170;this.K=139264};$CLJS.GM=function(a,b,c,d){return new $CLJS.FM(a,b,c,d,null,null,null)};$CLJS.HM=function(a){return new $CLJS.S(null,2,5,$CLJS.T,[EM,new $CLJS.k(null,2,[$CLJS.Vl,a,$CLJS.kk,function(){throw Error("should never be not called");}],null)],null)};$CLJS.JM=function(a,b,c){b=new $CLJS.k(null,2,[$CLJS.Vl,b,$CLJS.kk,c],null);return $CLJS.m(a)?b:$CLJS.Mg($CLJS.nf(IM,$CLJS.G([b])))};
$CLJS.Kga=function(a,b,c){return $CLJS.oh.h($CLJS.Mg($CLJS.nf(IM,$CLJS.G([new $CLJS.k(null,2,[$CLJS.Vl,a,$CLJS.kk,b],null)]))),c)};EM=new $CLJS.N("schema.spec.collection","remaining","schema.spec.collection/remaining",-421177821);KM=new $CLJS.N(null,"elements","elements",657646735);IM=new $CLJS.N("schema.spec.collection","optional","schema.spec.collection/optional",-854614927);LM=new $CLJS.N(null,"on-error","on-error",1728533530);MM=new $CLJS.N(null,"konstructor","konstructor",-1422324557);$CLJS.h=$CLJS.FM.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "pre":return this.na;case "konstructor":return this.sc;case "elements":return this.elements;case "on-error":return this.dc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};$CLJS.h.Td=$CLJS.La(54);
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.spec.collection.CollectionSpec{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rM,this.na],null),new $CLJS.S(null,2,5,$CLJS.T,[MM,this.sc],null),new $CLJS.S(null,2,5,$CLJS.T,[KM,this.elements],null),new $CLJS.S(null,2,5,$CLJS.T,[LM,this.dc],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.eh(this,4,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.rM,MM,KM,LM],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 4+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=1800698765^$CLJS.Ed(this)};
$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.na,b.na)&&$CLJS.F.h(this.sc,b.sc)&&$CLJS.F.h(this.elements,b.elements)&&$CLJS.F.h(this.dc,b.dc)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,4,[$CLJS.rM,null,KM,null,MM,null,LM,null],null),null),b)?$CLJS.vm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new $CLJS.FM(this.na,this.sc,this.elements,this.dc,this.F,$CLJS.Rf($CLJS.vm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "pre":case "konstructor":case "elements":case "on-error":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.rM,b):$CLJS.O.call(null,$CLJS.rM,b))?new $CLJS.FM(c,this.sc,this.elements,this.dc,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(MM,b):$CLJS.O.call(null,MM,b))?new $CLJS.FM(this.na,c,this.elements,this.dc,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(KM,b):$CLJS.O.call(null,KM,b))?new $CLJS.FM(this.na,this.sc,c,this.dc,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(LM,b):$CLJS.O.call(null,LM,b))?new $CLJS.FM(this.na,this.sc,this.elements,
c,this.F,this.m,null):new $CLJS.FM(this.na,this.sc,this.elements,this.dc,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.Je($CLJS.rM,this.na),new $CLJS.Je(MM,this.sc),new $CLJS.Je(KM,this.elements),new $CLJS.Je(LM,this.dc)],null),this.m))};$CLJS.h.O=function(a,b){return new $CLJS.FM(this.na,this.sc,this.elements,this.dc,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};